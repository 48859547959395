import { Badge, Stack, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/styles';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {
  const theme = useTheme();

  return (
    <LogoWrapper to="/overview">
      <Stack direction={'row'} sx={{ width: 500 }}>
        <img
          src={'/static/images/icons/icon-192x192.png'}
          alt={'jolt icon'}
          width={52}
        />
        <Stack direction={'column'} justifyContent={'center'}>
          <Typography variant={'overline'}>Jolt CRM</Typography>
          <Badge
            sx={{
              '.MuiBadge-badge': {
                fontSize: theme.typography.pxToRem(11)
              }
            }}
            overlap="circular"
            color="success"
            badgeContent={process.env.REACT_APP_VERSION}
          />
        </Stack>
      </Stack>
    </LogoWrapper>
  );
}

export default Logo;
