import { lazy, Suspense } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management

const Gait55AuthComplete = Loader(lazy(() => import('src/content/system/Integrations/single/Gait55')));


const integrationsRoutes = [
  {
    path: 'gait55/auth/complete',
    element: <Gait55AuthComplete />
  }
];

export default integrationsRoutes;
