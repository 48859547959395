import { useEffect, useState } from 'react';
import { useSelector } from '../store';
import axios from '../utils/axios';

const useEnvironment = () => {

  const debug = false;
  const [environmentReady, setEnvironmentReady] = useState(false);

  const {
    activeWorkspace,
    isLoading: workspaceLoading,
    workspacesCollected,
    workspaces
  } = useSelector((state) => state.workspaces);

  const {
    activeOrganization,
    isLoading: organizationLoading,
    organizationCollected,
    organizationsCollected,
    organizations
  } = useSelector((state) => state.organization);


  const buildDataObject = (object) => {
    if (!activeWorkspace) {
      throw new Error('Use Environment Build Data Object: Active Workspace is not set and is required to build a data object.');
    }

    if (!activeOrganization) {
      throw new Error('Use Environment Build Data Object: Active Organization is not set and is required to build a data object');
    }


    return { ...object, organization: activeOrganization._id, workspace: activeWorkspace._id };
  };

  useEffect(() => {
    if (debug) {
      console.log('useEnvironment', {
        environmentReady: (organizationsCollected && organizationCollected && activeOrganization && workspacesCollected && !organizationLoading && !workspaceLoading),
        organizationsCollected,
        organizationCollected,
        activeOrganization,
        workspacesCollected,
        organizationLoading,
        workspaceLoading
      });
    }
    setEnvironmentReady((axios.defaults.headers.common['x-jolt-organization'] && axios.defaults.headers.common['x-jolt-workspace'] && organizationCollected && activeOrganization && workspacesCollected && !organizationLoading && !workspaceLoading));
  }, [organizationsCollected, organizationCollected, activeOrganization, workspacesCollected, organizationLoading, workspaceLoading]);

  const getCurrentOrgDetails = () => activeOrganization;

  const checkOfferings = (...rest) => !Array.from(rest).map((arg) => activeOrganization?.offerings ? activeOrganization.offerings[arg] : false).includes(false);

  return {
    buildDataObject,
    checkOfferings,
    getCurrentOrgDetails,
    workspaces,
    organizations,
    workspace: activeWorkspace?._id,
    organization: activeOrganization?._id,
    environmentReady
  };
};

useEnvironment.propTypes = {};

export default useEnvironment;
