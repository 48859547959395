import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector
} from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer';

const store = configureStore({
  reducer: rootReducer,
  devTools: true
});

const {dispatch}=store;

 const useSelector = useReduxSelector;

 const useDispatch = () => useReduxDispatch();

export  { useSelector,store,dispatch,useDispatch };
