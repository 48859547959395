import {
  Badge,
  Stack,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
  useTheme
} from '@mui/material';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        display: flex;
        text-decoration: none;
        width: 153px;
        margin: 0 auto;
        font-weight: ${theme.typography.fontWeightBold};
`
);

const TooltipWrapper = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.colors.alpha.trueWhite[100],
    color: theme.palette.getContrastText(theme.colors.alpha.trueWhite[100]),
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 'bold',
    borderRadius: theme.general.borderRadiusSm,
    boxShadow:
      '0 .2rem .8rem rgba(7,9,25,.18), 0 .08rem .15rem rgba(7,9,25,.15)'
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.colors.alpha.trueWhite[100]
  }
}));

function Logo({ color, noText }) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <TooltipWrapper title={t('Jolt CRM')} arrow>
      <LogoWrapper to="/overview">
        <Stack direction={'row'} sx={{ width: 500 }}>
          <img
            src={'/static/images/icons/icon-192x192.png'}
            alt={'jolt icon'}
            width={52}
          />
          <Stack direction={'column'} justifyContent={'center'}>
            {!noText && (
              <Typography sx={{ color }} variant={'overline'}>
                Jolt CRM
              </Typography>
            )}
            <Badge
              sx={{
                marginLeft: noText ? theme.spacing(1) : 0,
                '.MuiBadge-badge': {
                  fontSize: theme.typography.pxToRem(11)
                }
              }}
              overlap="circular"
              color="success"
              badgeContent={process.env.REACT_APP_VERSION}
            />
          </Stack>
        </Stack>
      </LogoWrapper>
    </TooltipWrapper>
  );
}

Logo.propTypes = {
  color: PropTypes.string,
  noText: PropTypes.bool
};

export default Logo;
