// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_CUSTOMER = '/extended-sidebar';
const ROOTS_REGISTRATION = '';

// ----------------------------------------------------------------------

export const PATH_DASHBOARD = {
  root: ROOTS_CUSTOMER,
  register: {
    root: path(ROOTS_REGISTRATION, '/registration'),
    partner: path(ROOTS_REGISTRATION, '/registration/partner')
  },
  main: {
    root: path(ROOTS_CUSTOMER, '/')
  },
  dashboard: {
    root: path(ROOTS_CUSTOMER, '/dashboards')
  },
  accounts: {
    root: path(ROOTS_CUSTOMER, '/management/accounts/list'),
    view: path(ROOTS_CUSTOMER, '/management/accounts/single')
  },
  products: {
    root: path(ROOTS_CUSTOMER, '/management/products/list'),
    view: path(ROOTS_CUSTOMER, '/management/products/single'),
    create: path(ROOTS_CUSTOMER, '/management/products/create')
  },
  projects: {
    root: path(ROOTS_CUSTOMER, '/management/projects/list'),
    view: path(ROOTS_CUSTOMER, '/management/projects/single')
  },
  users: {
    root: path(ROOTS_CUSTOMER, '/management/users/list'),
    view: path(ROOTS_CUSTOMER, '/management/users/single')
  },
  integrations: {
    root: path(ROOTS_CUSTOMER, '/system/integrations/list'),
    view: (item) => path(ROOTS_CUSTOMER, `/system/integrations/single/${item}`)
  }
};
