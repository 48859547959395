import { createContext, useEffect, useState } from 'react';
import { capitalize, find } from 'lodash';
import PropTypes from 'prop-types';
import useEnvironment from '../hooks/useEnvironment';
import useAuth from '../hooks/useAuth';
import axiosGait55 from '../utils/axiosGait55';
import Loading from '../components/Page/Loading';

const debug = false;

const initialPermissions = {
  initialized: false,
  loading: false,
  role: null,
  permissions: null

};

export const PermissionsContext = createContext({
  permissions: initialPermissions,
  hasAccess: () => false
}); // component props type

const PermissionsProvider = ({ children, workspace, organization }) => {

  const [permissions, setPermissions] = useState(initialPermissions);

  const { environmentReady } = useEnvironment();

  const { user } = useAuth();

  useEffect(() => {

    if (debug) console.log('PermissionsProvider', {
      environmentReady,
      workspace,
      organization,
      user,
      permissions,
      userRole: user?.permissions ? capitalize(user.permissions[0]?.role) : null,
      permissionsRole: permissions?.role?.name
    });

    if (environmentReady && workspace && organization && user?.permissions && user?.permissions[0] && capitalize(user.permissions[0].role) !== permissions?.role?.name && !permissions.loading && !permissions.initialized) {

      setPermissions({
        ...permissions,
        loading: true
      });
      // TODO: Get the list of roles for the org / workspace in order to set the identifier on the user
      if (debug) console.log('axiosGait55 >> permissions');
      // axiosGait55.get('/permissions/103be3ef-5c8a-420f-ac04-4ebbdfda3790')
      if (debug) console.log('axiosGait55 >> permissions', { user });
      axiosGait55.get(`/permissions`, { params: { role: capitalize(user.permissions[0].name) } })
        .then((response) => {
          if (debug) console.log('axiosGait55 >> permissions', { response });
          const { data: { permissions = [], role = {} } } = response;
          setPermissions({
            role,
            permissions,
            loading: false,
            initialized: true
          });
        })
        .catch((error) => {
          console.error(error);
          setPermissions({
            role: {
              name: user.permissions[0].role
            },
            permissions: [],
            loading: false,
            initialized: true
          });
        });
    }
  }, [environmentReady, workspace, organization, user, permissions]);

  const hasAccess = ({ permission: permissionIdentifier, asset: assetIdentifier, role: roleIdentifier }) => {
    if (debug) console.log('hasAccess', {
      permissions,
      permissionIdentifier,
      assetIdentifier,
      roleIdentifier
    });
    if (!permissionIdentifier && !assetIdentifier && !roleIdentifier) {
      if (debug) console.log('hasAccess was given nothing', true);
      return true;
    }
    const assetAccess = !!find(permissions.permissions, (permission) => permission?.ids?.some((id) => id === assetIdentifier));
    const permissionAccess = !!find(permissions.permissions, { identifier: permissionIdentifier });
    const roleAccess = roleIdentifier && permissions?.role?.identifier === roleIdentifier;
    if (debug) console.log('hasAccess', assetAccess || roleAccess || permissionAccess);
    return assetAccess || roleAccess || permissionAccess;
  };

  return (
    <PermissionsContext.Provider
      value={{
        permissions,
        hasAccess
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

PermissionsProvider.propTypes = {
  workspace: PropTypes.string,
  organization: PropTypes.string,
  children: PropTypes.object
};

export default PermissionsProvider;

HasAccess.propTypes = {
  permission: PropTypes.string,
  asset: PropTypes.string,
  role: PropTypes.string,
  children: PropTypes.object
};

export function HasAccess({ permission, asset, role, children }) {
  if (debug) console.log('PermissionsProvider >> HasAccess', { permission, asset, role, children });
  return (
    <PermissionsContext.Consumer>
      {({ hasAccess }) => {
        if (hasAccess({ permission, asset, role })) {
          return children;
        }
        return null;
      }}

    </PermissionsContext.Consumer>
  );
}

PermissionsLoading.propTypes = {
  message: PropTypes.string,
  messageVariant: PropTypes.string,
  children: PropTypes.node,
  horizontalMenu: PropTypes.bool
};

export function PermissionsLoading({ message, messageVariant, children, horizontalMenu }) {

  if (debug) console.log('PermissionsProvider >> PermissionsLoading', { message, messageVariant });

  const baseProperties = {
    style: {
      backgroundColor: 'transparent',
      background: 'transparent'
    }
  };

  const messageProperties = {
    style: {
      backgroundColor: 'transparent',
      background: 'transparent'
    }
  };

  if (horizontalMenu) {
    messageProperties.style.marginTop = 25;
    baseProperties.style.height = 100;
    baseProperties.style.minHeight = 100;
  }

  return (
    <PermissionsContext.Consumer>
      {({ permissions }) => {
        const { loading, initialized } = permissions;
        const showLoading = loading || !initialized;
        if (debug) console.log('PermissionsProvider', { showLoading, loading, initialized });
        if (showLoading) {
          return (
            <Loading
              size={horizontalMenu ? 'small' : 'big'}
              message={message || 'Loading user permissions'}
              baseProperties={baseProperties}
              messageProperties={messageProperties}
              messageTypography={{
                variant: messageVariant || 'subtitle2'
              }}
              style={{ backgroundColor: 'transparent', background: 'transparent' }}
            />
          );
        }
        return children;
      }}

    </PermissionsContext.Consumer>
  );
}
