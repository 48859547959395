import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../utils/axios';
//
import { dispatch } from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  accountTypesCollected: false,
  error: null,
  currentOrg: null,
  accountTypes: []
};

const slice = createSlice({
  name: 'accountTypes',
  initialState,
  reducers: {
    // START LOADING
    startLoadingAccountTypes(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setCurrentOrganization(state, action) {
      const { organization } = action.payload;
      state.currentOrg = organization;
    },

    getAccountTypesSuccess(state, action) {
      state.isLoading = false;
      state.accountTypesCollected = true;
      const { accountTypes, organization } = action.payload;
      state.accountTypes = accountTypes;
      state.currentOrg = organization;
    },

    addAccountTypeSuccess(state, action) {
      const { type } = action.payload;
      state.accountTypes.push(type);
    },

    setActiveWorkspaceSuccess(state, action) {
      state.isLoading = false;
      state.activeWorkspace = action.payload;
    }
  }
});


// Reducer
export const reducer = slice.reducer;

// ----------------------------------------------------------------------

export function getAccountTypes(organization) {
  return async () => {
    dispatch(slice.actions.startLoadingAccountTypes());
    try {
      const response = await axios.get('/api/account/types');
      const { accountTypes } = response.data;
      dispatch(slice.actions.getAccountTypesSuccess({ accountTypes, organization }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function setCurrentOrganization(organization) {
  return async () => {
    try {
      dispatch(slice.actions.setCurrentOrganization({ organization }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addAccountType(type) {
  return async () => {
    try {
      dispatch(slice.actions.addAccountTypeSuccess({ type }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
