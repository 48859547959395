import { PureLightTheme } from './schemes/PureLightTheme';
import { GreyGooseTheme } from './schemes/GreyGooseTheme';
import { NebulaFighterTheme } from './schemes/NebulaFighterTheme';
import { DarkSpacesTheme } from './schemes/DarkSpacesTheme';
import { PurpleFlowTheme } from './schemes/PurpleFlowTheme';
import { GreenFieldsTheme } from './schemes/GreenFieldsTheme';
import { alpha } from '@mui/material';

const themeMap = {
  PureLightTheme,
  GreyGooseTheme,
  PurpleFlowTheme,
  NebulaFighterTheme,
  DarkSpacesTheme,
  GreenFieldsTheme
};

export function themeCreator(theme) {
  return {
    ...themeMap[theme],
    customShadows: {
      z1: `0 1px 2px 0 ${alpha('#919EAB', 0.16)}`,
      z8: `0 8px 16px 0 ${alpha('#919EAB', 0.16)}`,
      z12: `0 12px 24px -4px ${alpha('#919EAB', 0.16)}`,
      z16: `0 16px 32px -4px ${alpha('#919EAB', 0.16)}`,
      z20: `0 20px 40px -4px ${alpha('#919EAB', 0.16)}`,
      z24: `0 24px 48px 0 ${alpha('#919EAB', 0.16)}`
    }
  };
}
