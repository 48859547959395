import { lazy, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management

const Integrations = Loader(lazy(() => import('src/content/system/Integrations')));
const SingleIntegration = Loader(
  lazy(() => import('src/content/system/Integrations/single'))
);

const systemRoutes = [
  {
    path: '',
    element: <Navigate to='integrations' replace />
  },
  {
    path: 'integrations',
    children: [
      {
        path: '',
        element: <Navigate to='list' replace />
      },
      {
        path: 'list',
        element: <Integrations />
      },
      {
        path: 'single/:item',
        element: <SingleIntegration />
      }
    ]
  }
];

export default systemRoutes;
