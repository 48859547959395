import { createSlice } from '@reduxjs/toolkit';
// utils
//
import { dispatch } from '../store';
import axios from '../utils/axios';
//

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  allowances: [],
  allowancesLoaded: false
};

const slice = createSlice({
  name: 'package',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PACKAGES
    getAllowancesSuccess(state, action) {
      state.isLoading = false;
      state.allowancesLoaded = true;
      state.allowances = action.payload;
    },

    // GET PACKAGES
    createAllowancesSuccess(state, action) {
      state.isLoading = false;
      state.allowancesLoaded = true;
      state.allowances = [...state.allowances, ...action.payload];
    }
  }
});


// Reducer
export const reducer = slice.reducer;

// ----------------------------------------------------------------------

export function getAllowances(workspace, organization) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/allowances', { params: { workspace, organization } });
      dispatch(slice.actions.getAllowancesSuccess(response.data.allowances));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createAllowance(allowance) {
  return async () => {
    console.log({ allowance });
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put('/api/allowances', { allowances: [allowance] });
      dispatch(slice.actions.createAllowancesSuccess(response.data.allowances));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

