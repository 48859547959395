import ReactDOM from 'react-dom';
import 'src/mocks';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ScrollTop from 'src/hooks/useScrollTop';
import './utils/highlight';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './slick-carousel-overrides.css';

import 'nprogress/nprogress.css';
import { Provider } from 'react-redux';
import { store } from 'src/store';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { AuthProvider } from 'src/contexts/Auth0Context';
import { last } from 'lodash';

const getResults = () => {
  const hash = last(window.location.hash.split('#')) || window.location.hash;
  const search = new URLSearchParams(hash);
  const params = Object.fromEntries(search.entries());
  if (hash && window.location.pathname.includes('/3rdparty/gait55/auth/complete')) {
    window.localStorage.setItem('gait55Auth', JSON.stringify(params));
  }
};

getResults();

ReactDOM.render(
  <HelmetProvider>
    <Provider store={store}>
      <SidebarProvider>
        <BrowserRouter>
          <ScrollTop />
          <AuthProvider>
            <App />
          </AuthProvider>
        </BrowserRouter>
      </SidebarProvider>
    </Provider>
  </HelmetProvider>,
  document.getElementById('root')
);

serviceWorker.unregister();
