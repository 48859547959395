import { createSlice } from '@reduxjs/toolkit';

import _ from 'lodash';

import objectArray from 'src/utils/objectArray';
import axios from '../utils/axios';

const initialState = {
  isLoaded: false,
  lists: {
    byId: {},
    allIds: []
  },
  tasks: {
    byId: {},
    allIds: []
  },
  members: {
    byId: {},
    allIds: []
  }
};

const slice = createSlice({
  name: 'projects_board',
  initialState,
  reducers: {
    getBoard(state, action) {
      const { projects } = action.payload;
      const project = projects[0];

      state.lists.byId = objectArray(project.lists, '_id');
      state.lists.allIds = Object.keys(state.lists.byId);
      state.tasks.byId = objectArray(project.tasks, '_id');
      state.tasks.allIds = Object.keys(state.tasks.byId);
      state.members.byId = objectArray(project.members, '_id');
      state.members.allIds = Object.keys(state.members.byId);
      state.isLoaded = true;
    },

    updateList(state, action) {
      const { lists } = action.payload;
      const list = lists[0];
      if (list) {
        state.lists.byId[list._id] = list;
      }
    },

    updateTask(state, action) {
      const { tasks } = action.payload;
      const task = tasks[0];
      if (task) {
        state.tasks.byId[task._id] = task;
      }
    },

    addTask(state, action) {
      const { tasks } = action.payload;
      const task = tasks[0];
      if (task) {
        state.tasks.byId[task._id] = task;
        state.tasks.allIds.push(task._id);
        const { listId } = task;
        state.lists.byId[listId].taskIds.push(task._id);
      }
    },

    addList(state, action) {
      const { lists } = action.payload;
      const list = lists[0];
      if (list) {
        state.lists.byId[list._id] = list;
        state.lists.allIds.push(list._id);
      }
    },

    moveTask(state, action) {
      const { taskId, position, listId, task } = action.payload;

      const { listId: sourceListId } = state.tasks.byId[taskId];

      _.pull(state.lists.byId[sourceListId].taskIds, taskId);
      state.tasks.byId[taskId] = task;
      state.lists.byId[listId].taskIds.splice(position, 0, taskId);
    }
  }
});

export const reducer = slice.reducer;

export const getBoard = (_id) => async (dispatch) => {
  const response = await axios.get('/api/projects/board', { params: { _id } });

  dispatch(slice.actions.getBoard(response.data));
};

export const updateList = (listId, update) => async (dispatch) => {
  const response = await axios.post('/api/project/lists', {
    lists: [{
      _id: listId,
      ...update
    }]
  });

  dispatch(slice.actions.updateList(response.data));
};

export const updateProject = (_id, data) => async (dispatch) => {
  await axios.post('/api/projects', {
    projects: [{
      _id,
      ...data
    }]
  });

  const response = await axios.get('/api/projects/board', { params: { _id } });

  dispatch(slice.actions.getBoard(response.data));
};

export const deleteTask = (_id) => async (dispatch) => {

  await axios.delete('/api/project/tasks', {
    params: {
      tasks: _id
    }
  });

  const response = await axios.get('/api/projects/board', { params: { _id } });

  dispatch(slice.actions.getBoard(response.data));
};

export const updateTask = (task, _id) => async (dispatch) => {
  const response = await axios.post('/api/project/tasks', {
    tasks: [{
      _id,
      ...task
    }]
  });

  dispatch(slice.actions.updateTask(response.data));
};

export const addTask = (task) => async (dispatch) => {
  let response = { data: { tasks: [task] } };
  if (!task._id) {
    response = await axios.put('/api/project/tasks', {
      tasks: [{
        ...task
      }]
    });
  }

  dispatch(slice.actions.addTask(response.data));
};

export const addList = () => async (dispatch) => {
  const response = await axios.put('/api/project/lists', {
    lists: [{
      name: ''
    }]
  });

  dispatch(slice.actions.addList(response.data));
};

export const moveTask = (taskId, position, listId, status) => async (dispatch) => {
  const response = await axios.post(`/api/project/tasks/move`, {
    tasks: [{
      _id: taskId,
      position,
      status,
      listId
    }]
  });

  dispatch(
    slice.actions.moveTask({
      taskId,
      position,
      listId,
      task: response.data.tasks[0]
    })
  );
};

export default slice;
