import { useEffect, useState } from 'react';
import { each, find } from 'lodash';
import axios from '../utils/axios';
import menuItems from '../layouts/ExtendedSidebarLayout/Sidebar/SidebarMenu/items';

// ----------------------------------------------------------------------

export default function useNavigation() {

  const [navigation, setNavigation] = useState([]);

  useEffect(() => {
    axios.get('/api/catalog/component?depth=1')
      .then((response) => {
        const { data: { catalog } } = response;

        each(menuItems, (component, index) => {
          const asset = find(catalog, (asset) => asset.name.toLowerCase() === component.heading.toLowerCase());
          menuItems[index].identifier = asset?.identifier || menuItems[index].identifier || '2';
          each(component.items, (subName, subIndex) => {
            const func = find(asset?.functions, (asset) => asset.name.toLowerCase() === subName.name.toLowerCase());
            menuItems[index].items[subIndex].identifier = func?.identifier || menuItems[index].items[subIndex].identifier || '1';
          });
        });
        setNavigation(menuItems);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return {
    menuItems: navigation
  };
}
