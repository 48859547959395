import { createSlice } from '@reduxjs/toolkit';
// utils
//
import { dispatch } from '../store';
import axios from '../utils/axios';
//

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  roles: [],
  rolesCollected: false
};

const slice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET ROLES
    getRolesSuccess(state, action) {
      state.isLoading = false;
      state.rolesCollected = true;
      state.roles = action.payload;
    }
  }
});


// Reducer
export const reducer = slice.reducer;

// ----------------------------------------------------------------------

export function getRoles(workspace, organization) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/roles', { params: { workspace, organization } });
      dispatch(slice.actions.getRolesSuccess(response.data.roles));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
