import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../utils/axios';
//
import { dispatch } from '../store';
//

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  accountIndustriesCollected: false,
  error: null,
  accountIndustries: []
};

const slice = createSlice({
  name: 'accountIndustries',
  initialState,
  reducers: {
    // START LOADING
    startLoadingAccountIndustries(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getAccountIndustriesSuccess(state, action) {
      state.isLoading = false;
      state.accountIndustriesCollected = true;
      const { accountIndustries } = action.payload;
      state.accountIndustries = accountIndustries;
    },

    addAccountIndustrySuccess(state, action) {
      const { type } = action.payload;
      state.accountIndustries = [...state.accountIndustries, type];
    },

    setActiveWorkspaceSuccess(state, action) {
      state.isLoading = false;
      state.activeWorkspace = action.payload;
    }
  }
});


// Reducer
export const reducer = slice.reducer;

// ----------------------------------------------------------------------

export function getAccountIndustries() {
  return async () => {
    dispatch(slice.actions.startLoadingAccountIndustries());
    try {
      const response = await axios.get('/api/account/industries');
      const { accountIndustries } = response.data;
      dispatch(slice.actions.getAccountIndustriesSuccess({ accountIndustries }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function addAccountIndustry(type) {
  return async () => {
    try {
      dispatch(slice.actions.addAccountIndustrySuccess({ type }));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
