import { lazy, Suspense } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Management

const Registration = Loader(lazy(() => import('src/content/register')));
const Partner = Loader(lazy(() => import('src/content/partner')));

const registrationRoutes = [
  {
    path: '',
    element: <Registration />
  },
  {
    path: 'partner',
    element: <Partner />
  }
];

export default registrationRoutes;
