import { useEffect, useRef, useState } from 'react';
import {
  alpha,
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  Divider,
  Grid,
  Link,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Stack,
  styled,
  Typography,
  useTheme
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone';
import { useDispatch, useSelector } from '../../../../store';
import {
  getOrganization,
  getOrganizations,
  setActiveOrganization,
  setOrganization
} from '../../../../slices/organization';
import { setOrganizationSession, setWorkspaceSession } from '../../../../utils/jwt';
import { getWorkspaces, setActiveWorkspace } from '../../../../slices/workspaces';
import useAuth from '../../../../hooks/useAuth';
import { filter, flatMap } from 'lodash';

const CardActionAreaWrapper = styled(CardActionArea)(
  ({ theme }) => `
      .MuiTouchRipple-root {
        opacity: .2;
      }

      .MuiCardActionArea-focusHighlight {
        background: ${theme.colors.primary.main};
      }

      &:hover {
        .MuiCardActionArea-focusHighlight {
          opacity: .05;
        }
      }
`
);

const MenuListWrapperSecondary = styled(MenuList)(
  ({ theme }) => `
  padding: ${theme.spacing(3)};

  & .MuiMenuItem-root {
      border-radius: 50px;
      padding: ${theme.spacing(1, 1, 1, 2.5)};
      min-width: 200px;
      margin-bottom: 2px;
      position: relative;
      color: ${theme.colors.alpha.black[70]};

      &.Mui-selected,
      &:hover,
      &.MuiButtonBase-root:active {
          background: ${theme.colors.alpha.black[10]};
          color: ${theme.colors.alpha.black[100]};
      }

      &:last-child {
          margin-bottom: 0;
      }
    }
`
);

function HeaderMenu() {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { user } = useAuth();

  const theme = useTheme();

  const {
    workspaces,
    activeWorkspace
  } = useSelector((state) => state.workspaces);

  const {
    activeOrganization,
    organizations,
    organization,
    organizationsCollected,
    organizationCollected,
    isLoading: loadingOrganizations
  } = useSelector((state) => state.organization);

  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    if (user && user.permissions?.length > 0 && !loadingOrganizations && !organizationCollected) {
      dispatch(getOrganization(user.permissions[0].organization, window.localStorage.getItem('activeOrganization')));
    }
  }, [user, dispatch, loadingOrganizations, organizationCollected]);

  useEffect(() => {
    if (user && user.permissions?.length > 0 && !loadingOrganizations && !organizationsCollected) {
      dispatch(getOrganizations(flatMap(user.permissions, 'organization').join(','), window.localStorage.getItem('activeOrganization')));
    }
  }, [user, dispatch, loadingOrganizations, organizationCollected, organizationsCollected]);

  useEffect(() => {
    if (organizationsCollected && organizationCollected && !activeOrganization) {
      dispatch(setActiveOrganization(organization));
    }
  }, [organizationsCollected, activeOrganization, organizationCollected, organization, dispatch]);

  useEffect(() => {
    setOrganizationSession(activeOrganization?._id);
  }, [activeOrganization]);

  useEffect(() => {
    setWorkspaceSession(activeWorkspace?._id);
  }, [activeWorkspace]);

  useEffect(() => {
    if (activeOrganization) {
      dispatch(getWorkspaces(activeOrganization._id, window.localStorage.getItem('activeWorkspace')));
    }
  }, [activeOrganization, dispatch]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ref2 = useRef(null);
  const [isOpen2, setOpen2] = useState(false);

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  return (
    <>
      <Box
        sx={{
          display: { xs: 'none', md: 'inline-flex' }
        }}
      >
        <Button
          ref={ref}
          onClick={handleOpen}
          endIcon={<KeyboardArrowDownTwoToneIcon />}
          color='secondary'
          size='small'
          sx={{
            mr: 1,
            px: 2,
            backgroundColor: `${theme.colors.secondary.lighter}`,
            color: `${theme.colors.secondary.dark}`,

            '.MuiSvgIcon-root': {
              color: `${theme.colors.secondary.dark}`,
              transition: `${theme.transitions.create(['color'])}`
            },

            '&:hover': {
              backgroundColor: `${theme.colors.secondary.main}`,
              color: `${theme.palette.getContrastText(
                theme.colors.secondary.main
              )}`,

              '.MuiSvgIcon-root': {
                color: `${theme.palette.getContrastText(
                  theme.colors.secondary.main
                )}`
              }
            }
          }}
        >
          <Stack spacing={1} alignItems={'flex-start'} sx={{ height: '100%' }}>
            <Typography variant={'overline'} sx={{ p: 0, mt: -1, mb: -1 }}>{t('Workspace')}</Typography>
            {activeWorkspace?.name}
          </Stack>
        </Button>
        <Button
          ref={ref2}
          onClick={handleOpen2}
          endIcon={<KeyboardArrowDownTwoToneIcon />}
          color='secondary'
          size='small'
          sx={{
            px: 2,
            backgroundColor: `${theme.colors.secondary.lighter}`,
            color: `${theme.colors.secondary.dark}`,

            '.MuiSvgIcon-root': {
              color: `${theme.colors.secondary.dark}`,
              transition: `${theme.transitions.create(['color'])}`
            },

            '&:hover': {
              backgroundColor: `${theme.colors.secondary.main}`,
              color: `${theme.palette.getContrastText(
                theme.colors.secondary.main
              )}`,

              '.MuiSvgIcon-root': {
                color: `${theme.palette.getContrastText(
                  theme.colors.secondary.main
                )}`
              }
            }
          }}
        >
          <Stack spacing={0} alignItems={'flex-start'}>
            <Typography variant={'overline'} sx={{ p: 0, mt: -1, mb: -1 }}>{t('Organization')}</Typography>
            {activeOrganization?.name}
          </Stack>
        </Button>
      </Box>
      <Popover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box
          sx={{
            p: 2,
            background: alpha(theme.colors.alpha.black[100], 0.06)
          }}
          display='flex'
          alignItems='center'
          justifyContent='space-between'
        >
          <Box>
            <Typography
              sx={{
                pb: 0.5
              }}
              variant='h4'
            >
              {t('Workspaces')}
            </Typography>
            <Typography noWrap variant='subtitle2'>
              {t('All currently active workspaces')}
            </Typography>
          </Box>
          <Link
            href='#'
            variant='subtitle2'
            sx={{
              p: 2,
              top: 0,
              right: 0,
              position: 'absolute',
              textTransform: 'none',
              display: { xs: 'none', lg: 'inline-block' }
            }}
          >
            {t('View all')}
          </Link>
        </Box>
        <Divider />
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          divider={<Divider orientation='vertical' flexItem />}
          justifyContent='stretch'
          alignItems='stretch'
          spacing={0}
        >
          <MenuListWrapperSecondary disablePadding>
            {workspaces.map((workspace) => (
              <MenuItem key={workspace?._id} selected={workspace?._id === activeWorkspace?._id}
                        onClick={() => {
                          dispatch(setActiveWorkspace(workspace));
                        }}>
                <ListItemText
                  primaryTypographyProps={{
                    variant: 'h5'
                  }}
                  primary={workspace.name}
                />
                <ChevronRightTwoToneIcon
                  sx={{
                    color: workspace?._id === activeWorkspace?._id ? '' : `${theme.colors.alpha.black[30]}`,
                    opacity: 0.8
                  }}
                />
              </MenuItem>
            ))}
          </MenuListWrapperSecondary>
        </Stack>
      </Popover>
      <Popover
        disableScrollLock
        anchorEl={ref2.current}
        onClose={handleClose2}
        open={isOpen2}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{
          '.MuiPopover-paper': {
            background: theme.colors.gradients.blue3
          }
        }}
      >
        <Box
          sx={{
            maxWidth: 400
          }}
          p={3}
        >
          <Typography
            variant='h4'
            gutterBottom
            textAlign='center'
            sx={{
              color: theme.colors.alpha.trueWhite[100],
              fontSize: theme.typography.pxToRem(18)
            }}
          >
            {t('Organizations')}
          </Typography>
          <Typography
            variant='subtitle2'
            textAlign='center'
            sx={{
              color: theme.colors.alpha.trueWhite[70]
            }}
          >
            {t('All sub-organizations you have access to')}
          </Typography>
          <Link
            href='#'
            variant='subtitle2'
            sx={{
              p: 2,
              top: 0,
              right: 0,
              position: 'absolute',
              textTransform: 'none',
              display: { xs: 'none', lg: 'inline-block' }
            }}
          >
            {t('View all')}
          </Link>
          <Grid container mt={1} spacing={2}>
            {filter(organizations, (org) => org?._id !== activeOrganization?._id).map((org) => (
              <Grid key={org._id} item xs={12} sm={6}>
                <Card>
                  <CardActionAreaWrapper
                    sx={{
                      p: 2
                    }}
                    onClick={() => {
                      dispatch(setOrganization(org));
                      handleClose2();
                    }}
                  >
                    <Avatar src={org.avatar} />
                    <Typography variant='subtitle1'>{org.name}</Typography>
                  </CardActionAreaWrapper>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Popover>
    </>
  );
}

export default HeaderMenu;
