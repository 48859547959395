import { combineReducers } from '@reduxjs/toolkit';
import { reducer as accountTypesReducer } from 'src/slices/accountTypes';
import { reducer as accountIndustriesReducer } from 'src/slices/accountIndustries';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as projectsBoardReducer } from 'src/slices/projects_board';
import { reducer as mailboxReducer } from 'src/slices/mailbox';
import { reducer as organizationReducer } from 'src/slices/organization';
import { reducer as workspacesReducer } from 'src/slices/workspaces';
import { reducer as rolesReducer } from 'src/slices/role';
import { reducer as pipelineReducer } from 'src/slices/pipeline';
import { reducer as locationsReducer } from 'src/slices/location';
import { reducer as allowancesReducer } from 'src/slices/allowances';

const rootReducer = combineReducers({
  locations: locationsReducer,
  allowances: allowancesReducer,
  roles: rolesReducer,
  pipeline: pipelineReducer,
  accountIndustries: accountIndustriesReducer,
  accountTypes: accountTypesReducer,
  calendar: calendarReducer,
  organization: organizationReducer,
  workspaces: workspacesReducer,
  projectsBoard: projectsBoardReducer,
  mailbox: mailboxReducer
});

export default rootReducer;
